import React from "react"
import {Link} from "gatsby";
import WorkArrow from "./workArrow";
import team11 from "../images/team/team-1-1.png";
import team12 from "../images/team/team-1-2.png";
import team13 from "../images/team/team-1-3.png";
import team14 from "../images/team/team-1-4.png";
import team15 from "../images/team/team-1-5.png";
import ImageSlider from "./imageSlider";

function WorkBox({ ...props }){
    var videoMP4 = '';
    var posterImage = '';

    if(props.work.type === 'image'){
        var image = require('../works/'+props.work.image);
    }
    if(props.work.type === 'video'){
        var video = require('../works/'+props.work.image+'.webm').default;
        videoMP4 = require('../works/'+props.work.image+'.mp4').default;
    }
    if(props.work.type === 'vimeo'){
        videoMP4 = props.work.image;
    }

    if(props.work.poster){
        posterImage = require('../works/'+props.work.poster);
    }



    return (
        <Link to={'/our-work/'+props.work.slug}>
            <div className="work-box">
                <div className={'image'}>
                    {props.work.type === 'image' && <img src={image.default} alt={props.work.title}/>}
                    {props.work.type === 'video' &&
                        <video autoPlay={true} preload={'none'} loop={props.work.loop} playsInline={true} muted={true}>
                            <source src={videoMP4}/>
                            {video && <source src={video} type="video/webm" />}
                        </video>
                    }
                    {props.work.type === 'vimeo' &&
                        <video autoPlay={true} preload={'none'} loop={props.work.loop} playsInline={true} muted={true} poster={posterImage?.default} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                            <source src={videoMP4}/>
                        </video>
                    }
                </div>
                <h2 className={'title'}>{props.work.title} <WorkArrow/></h2>
                <div className={'subtitle'}>{props.work.subtitle}</div>
            </div>
        </Link>
    )
}

export default WorkBox
