import React from 'react';

const ImageSlider = ({images, time, timing = 'section-image-slide'}) => {
    const containerSize = images.length*100;
    const itemSize = 100 / images.length;
    return (
        <div className={'image-slider'}>
            <ul style={{animationDuration: `${time}s`, animationName: timing, width: `${containerSize}%`}}>
                {images.map((image, index) => (
                    <li key={index} style={{width:`${itemSize}%`}}>
                        <div className={'slide'}>
                            <img src={image} alt="Designatives" width={'100%'}/>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ImageSlider;