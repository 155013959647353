import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header";
import Footer from "../components/footer";
import WorkBox from "../components/work-box";
import {Helmet} from "react-helmet";

import works from "../contents/works.json";

const WorksPage = () => {
    const title = 'Our Work - Explore Unique Projects Across Branding, Web, and More';
    const description = 'Discover our diverse portfolio of projects, ranging from branding and web design to app development, 3D visuals, and video production. See how we bring creative ideas to life for clients in Hungary and beyond.';
    return (
        <main>
            <Helmet>
                <title>{title}</title>

                <meta name="description" content={description}/>
                <meta name="DC.description" content={description}/>
                <meta name="DC.title" content={title}/>

                <meta property="og:description" content={description}/>
                <meta property="og:site_name" content={title}/>

                <meta name="twitter:description" content={description}/>
            </Helmet>
            <Header/>
            <Layout>
                <div className={'works-page'}>
                    <section className="">
                        <div className="container">
                            <div className="row h-100 align-items-center">
                                <div className={'col-12 col-md-6 order-2 order-md-1'}>
                                    <h1 className={'level1'}>Our Work</h1>
                                </div>
                            </div>
                            <div className="row">
                                {works.map((work, index) => {
                                    return (
                                        work.visible && <div key={index} className={'col-12 col-md-6'}>
                                            <WorkBox index={index} work={work} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>
                </div>

            </Layout>
            <Footer/>
        </main>
    )
}

export default WorksPage
